#filter-input {
    float: right;
    padding: 5px 10px;
    background-color: rgba(166, 165, 165, 0.3);
    border: none;
    border-radius: 4px;
    outline: none;

    &::placeholder {
        color: $secondaryColor
    }
}

label {
    float: right;
    padding: 0 10px;
    border: none;
    outline: none;
    transition: 0.3s
}