footer {
    width: 100%;
    max-width: $desktop;
    margin: 30px auto;
    text-align: right;

    li {
        display: inline-block;
        padding: 3px 10px
    }

    a {
        color: $secondaryColor;
        text-decoration: none
    }
}