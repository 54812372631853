body {
    background-color: $primaryColor;
    color: $secondaryColor;
    font-family: $primaryFont
}

main {
    width: 100%;
    max-width: $desktop;
    margin: 30px auto
}

a {
    color: $secondaryColor;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
        opacity: 0.7
    }

    &.site-title {
        font-size: 2.8em;
        font-weight: 100;
        display: inline-block;
        margin-bottom: 50px;

        &::after {
            content: url(../img/powered.png);
            vertical-align: 14px
        }
    }
}

.nowrap {
    white-space: nowrap
}